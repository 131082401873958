import React from "react"
import VideoItem from "./VideoItem"

const Tutorials = ({ data }) => {

    return (
        <>
            <div className="pt-16 pb-8 md:pb-32 gradientBlue ">
                <div className="container px-4">
                    <div className="text-editor mb-12 " dangerouslySetInnerHTML={{ __html: data.titleMainVideo }} />
                    <div className="md:w-8/12 mx-auto shadow rounded">
                        <div className="aspect-w-16 aspect-h-9 ">
                            <iframe
                                className="w-full h-full"
                                src={`https://www.youtube.com/embed/${data.embedIdMainVideo}`}
                                title={data.titleMainVideo}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pb-16 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 px-4">
                {data.youtubeLink && data.youtubeLink.map((v) => {
                    return (
                        <VideoItem key={v.id} data={v} />
                    )
                })}
            </div>
        </>
    )
}

export default Tutorials

