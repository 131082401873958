import React, { useRef } from "react"
import { useLockBodyScroll, useOnClickOutside } from "../../utils/hooks";
import CloseIcon from "../icons/close";



const VideoModal = ({ children, showModal, setShowModal }) => {

    useLockBodyScroll();
    const ref = useRef();
    useOnClickOutside(ref, () => modalHandle());

    const modalHandle = () => {
        setShowModal(false)
    }

    return (
        <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="container flex items-center md:py-8 h-full md:w-10/12 lg:w-9/12 shadow-lg">
                <div className="relative w-full">
                    <div className="absolute -top-7 right-1">
                        <button onClick={modalHandle} className="flex focus:outline-none">
                            <CloseIcon width="18" fill="#fff" />
                        </button>
                    </div>
                    <div ref={ref}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoModal