import React, { useState } from "react"
import VideoModal from "../Shared/VideoModal"


const VideoItem = ({ data }) => {


    const [showModal, setShowModal] = useState(false);

    const playVideo = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <div className="shadow rounded">
                <button className="video-button w-full relative flex items-center justify-center focus:outline-none" onClick={playVideo}>
                    <img className="w-full" alt="" src={`https://i.ytimg.com/vi/${data.embedId}/mqdefault.jpg`} />
                    <div className="absolute">
                        <div className="play-button" />
                    </div>
                </button>
                <div className="px-4" dangerouslySetInnerHTML={{ __html: data.videoDescription }} />
            </div>
            {showModal &&
                <VideoModal showModal={showModal} setShowModal={setShowModal}>
                    <iframe
                        className="w-full h-vh85 aspect-w-video"
                        src={`https://www.youtube.com/embed/${data.embedId}/?autoplay=1`}
                        title={data.videoDescription}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </VideoModal>
            }
        </>
    )
}


export default VideoItem