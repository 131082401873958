import React from "react"
import { graphql } from "gatsby"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import Tutorials from "../components/TutorialsPage.js/Tutorials";

const TutorialsPage = ({ data }) => {

  const tutorialsData = data.allStrapiTutorials.edges[0].node;

  return (
    <>
      <SEO seo={tutorialsData.metaData} />
      <LayoutHeadFoot>
        <Tutorials data={tutorialsData} />
      </LayoutHeadFoot>
    </>
  )
}

export const query = graphql`
query TutorialsQuery {
    allStrapiTutorials {
    edges {
      node {
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
        titleMainVideo
        embedIdMainVideo
        youtubeLink {
            embedId
            id
            videoDescription
        }
      }
    }
  }
}`

export default TutorialsPage


